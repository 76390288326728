import React from 'react'
import { makeStyles } from '@material-ui/core'

interface LogoProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'default' | 'dark'
}

const Logo = (props: LogoProps): JSX.Element => {
  const classes = useLogoStyles()
  const { size = 'medium', variant = 'default' } = props

  const styles: any = { height: 60, width: 180 }
  if (size === 'large') {
    styles.height = undefined
    styles.width = 600
  }

  return (
    <img
      alt="Logo"
      src={variant === 'dark' ? '/static/images/logo_wide_alt.svg' : '/static/images/logo_wide.svg'}
      style={styles}
      className={variant === 'dark' ? classes.logoClassDark : classes.logoClass}
      {...props}
    />
  )
}
export const useLogoStyles = makeStyles(theme => ({
  logoClass: {
    border: '1px solid #fff',
  },
  logoClassDark: {},
}))

export default Logo
