import LoadingScreen from 'components/LoadingScreen'
import { AppContext } from 'contexts'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Api from 'utils/Api'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const ConfirmPage: React.FunctionComponent = () => {
  const history = useHistory()
  const { handleError, flash } = useContext(AppContext)
  const { t } = useTranslation()
  const query = useQuery()

  const code = query.get('code')

  useEffect(() => {
    Api.request({
      method: 'POST',
      url: `/users/register-confirm`,
      data: {
        code,
      },
    })
      .then(res => {
        flash(t('account.your_user_verified').toString())
        history.replace('/auth/signin')
      })
      .catch(handleError)
  }, [])

  return (
    <>
      <LoadingScreen />
    </>
  )
}
