import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ApikeysTable } from './ApikeysTable'
import { ChangePassword } from './ChangePassword'
import { ProfileForm } from './ProfileForm'

export const ProfilePage: React.FunctionComponent = () => {
  const [session] = useContext(SessionContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [isProfileEditing, setIsProfileEditing] = useState<boolean>(false)

  if (!session.user) return null
  const { user } = session

  return (
    <Page title={t('profile.my_profile')} padded>
      <PaperBlock title={t('profile.my_profile')} desc={t('profile.my_profile_subtitle')}>
        <Box alignItems="center" display="flex" flexDirection="column" p={4}>
          <Avatar className={classes.avatar} />

          <Typography gutterBottom variant="h2">
            {user.name}
          </Typography>
          <Typography gutterBottom variant="h4">
            {user.email}
          </Typography>

          <Box m={1} />

          <Typography gutterBottom variant="h5">
            {user.roles.join(', ')}
          </Typography>

          <Typography gutterBottom variant="h5">
            {user.partnerId} {user.partner?.name}
          </Typography>

          <Box m={1} />

          <Typography gutterBottom variant="caption">
            ID: {user.id}
          </Typography>
        </Box>

        <Box display="flex">
          <Button
            variant="outlined"
            onClick={() => {
              setIsProfileEditing(true)
            }}
          >
            {t('profile.edit_profile')}
          </Button>
          <div style={{ width: 10 }} />

          <ChangePassword />

          <div style={{ width: 10 }} />

          <Button
            variant="outlined"
            onClick={() => {
              history.push(`/profile/twofa`)
            }}
          >
            {t('profile.twofa')}
          </Button>
        </Box>

        <Dialog
          open={isProfileEditing}
          onClose={() => {
            setIsProfileEditing(false)
          }}
        >
          <DialogContent>
            <ProfileForm
              user={user}
              onCancel={() => {
                setIsProfileEditing(false)
              }}
              onProfileUpdated={() => {
                setIsProfileEditing(false)
              }}
            />
          </DialogContent>
        </Dialog>
      </PaperBlock>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 90,
    height: 90,
    margin: theme.spacing(1),
  },
}))
