import axios, { AxiosError, AxiosResponse } from 'axios'

class ApiErrorParser {
  constructor(public error: any) {}

  toString(): any {
    return this.formatError(this.error)
  }

  formatError(arg: any): any {
    // if (process.env.NODE_ENV === 'development') console.error('Axios error', arg?.response)

    if (arg instanceof Object && (arg as AxiosError).response) {
      const a = arg as AxiosError

      if (a.response && a.response.data && a.response.data.errors) {
        if (Array.isArray(a.response.data.errors)) {
          return a.response.data.errors.map((m: any) => m)[0]
        }
        return JSON.stringify(a.response.data.errors)
      } else if (a.response && a.response.data && a.response.data.error && a.response.data.error.statusCode) {
        if (typeof a.response.data.error.message === 'string' && a.response.data.error.message) {
          return a.response.data.error.message
        } else if (a.response.data.error.message && typeof a.response.data.error.message.message === 'string') {
          return a.response.data.error.message.message
        }
        return a.response.data.error.name
      } else if (a.response && a.response.status && a.response.statusText) {
        return a.response.statusText
      }
      return a
    } else if (arg instanceof Object && (arg as AxiosResponse).status && (arg as AxiosResponse).statusText) {
      return (arg as AxiosResponse).statusText
    } else if (arg instanceof Error) {
      return arg.message
    } else if (typeof arg === 'string') {
      return arg
    }
  }
}

export default ApiErrorParser
