import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, makeStyles, TextField } from '@material-ui/core'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'

interface ChangePasswordField {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const ChangePassword: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { handleError, flash } = useContext(AppContext)
  const [state] = useContext(SessionContext)
  const [open, setOpen] = useState<boolean>(false)

  const { control, errors, handleSubmit, watch } = useForm<ChangePasswordField>({
    defaultValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
    mode: 'onBlur',
  })

  const newPassword = watch('newPassword', '')

  const onSubmit = (values: ChangePasswordField) => {
    Api.request({
      method: 'POST',
      url: `/users/${state.user?.id}/change-password`,
      data: {
        currentPassword: values.currentPassword,
        password: values.newPassword,
      },
    })
      .then(res => {
        setOpen(false)
        flash(t('profile.password_changed_successfully'))
      })
      .catch(handleError)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true)
        }}
      >
        {t('profile.change_password')}
      </Button>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <DialogTitle>{t('profile.change_password')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('profile.change_password_instruction')}</DialogContentText>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl margin="normal" fullWidth>
              <Controller
                control={control}
                name="currentPassword"
                render={({ value, onChange }) => (
                  <TextField
                    autoFocus
                    label={t('profile.current_password')}
                    type="password"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={errors.currentPassword ? errors.currentPassword.message : ''}
                    FormHelperTextProps={{ error: errors.currentPassword ? true : false }}
                  />
                )}
                rules={{
                  required: { value: true, message: 'Required' },
                }}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Controller
                control={control}
                name="newPassword"
                render={({ value, onChange }) => (
                  <TextField
                    label={t('profile.new_password')}
                    type="password"
                    value={value}
                    onChange={onChange}
                    helperText={errors.newPassword ? errors.newPassword.message : ''}
                    FormHelperTextProps={{ error: errors.newPassword ? true : false }}
                  />
                )}
                rules={{
                  required: { value: true, message: t('errors.input_required') },
                  pattern: {
                    value: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{6,}$/,
                    message: t('errors.password_strength'),
                  },
                }}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Controller
                control={control}
                name="confirmNewPassword"
                render={({ value, onChange }) => (
                  <TextField
                    label={t('profile.confirm_new_password')}
                    type="password"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={errors.confirmNewPassword ? errors.confirmNewPassword.message : ''}
                    FormHelperTextProps={{ error: errors.confirmNewPassword ? true : false }}
                  />
                )}
                rules={{
                  required: { value: true, message: t('errors.input_required') },
                  // pattern: { value: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{6,}$/, message: t('errors.password_strength') },
                  validate: (val: string) => val === newPassword || t('errors.password_missmatch').toString(),
                }}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Button variant="contained" type="submit" color="primary">
                {t('common.submit')}
              </Button>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

// const useStyles = makeStyles(theme => ({
//   avatar: {
//     width: 90,
//     height: 90,
//     margin: theme.spacing(1),
//   },
// }))
