import React, { PropsWithChildren, useContext, useEffect, useReducer, useState } from 'react'
import { Currency, Country } from 'models'
import Api from 'utils/Api'
import { SessionContext } from './SessionContext'

export type ResourceState = {
  loading: boolean
  currencies: Currency[]
  countries: Country[]
  markets: any[]
  tickers: Record<string, never>
}

const initialState: ResourceState = {
  loading: true,
  currencies: [],
  countries: [],
  markets: [],
  tickers: {},
}

const ResourceContext = React.createContext<[ResourceState]>([initialState])

const ResourceProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }: PropsWithChildren<unknown>) => {
  const [state, setState] = useState<ResourceState>(initialState)
  const [session] = useContext(SessionContext)

  useEffect(() => {
    if (!session.accessToken) {
      setState(state => ({ ...state, loading: false }))
      return
    }

    Api.defaults.headers.common['Authorization'] = 'Bearer ' + session.accessToken

    Promise.all([
      Api.request<Currency[]>({
        method: 'GET',
        url: `/currencies`,
        // params: {
        //   filter: {
        //     order: ['position ASC'],
        //     // include: [{ relation: 'attributes' }],
        //   },
        // },
      }),
      Api.request<Country[]>({
        method: 'GET',
        url: '/countries',
      }),
    ]).then(([res1, res2]) => {
      setState(state => ({ ...state, currencies: res1.data, countries: res2.data, loading: false }))
    })

    // return () => {
    //   //TODO
    // }
    return
  }, [session.accessToken])

  return <ResourceContext.Provider value={[state]}>{state.loading ? null : children}</ResourceContext.Provider>
}

export { ResourceContext, ResourceProvider }
