import { User, Currency } from './'

export class Account {
  constructor(
    public id: string,
    public userId: undefined | string,
    public currencyId: string,
    public number: string,
    public balance?: number,
    public locked?: number,
    public user?: User,
    public currency?: Currency,
  ) {}

  public isFiatCurrency(): boolean {
    return true
  }
}

export class AccountBalance {
  constructor(
    public accountId: string,
    public balance: number,
    public creditPending: number,
    public id: string,
    public lastUpdate: string,
    public locked: number,
  ) {}
}