import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TwoFARequestAuthentication } from './TwoFARequestAuthentication'

export const TwoFAPage: React.FunctionComponent = () => {
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()

  if (!session.user) return null

  return (
    <Page title={t('profile.twofa')} padded>
      <PaperBlock title={t('profile.twofa')} desc={t('profile.twofa_subtitle')}>
        <TwoFARequestAuthentication />
      </PaperBlock>
    </Page>
  )
}
