import Page from 'components/Page'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SessionContext } from 'contexts/SessionContext'
import { Redirect } from 'react-router'

export const SignoutPage: React.FunctionComponent = () => {
  const [session, sessionDispatch] = useContext(SessionContext)
  const { t } = useTranslation()

  useEffect(() => {
    sessionDispatch({ type: 'SIGNOUT' })
    window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
  }, [])

  return <Redirect to="/auth/signout" />
}
