export enum QuoteStatus {
  PENDING = 'pending',
}
export class QuoteFee {
  constructor(public id: string, public createdTime: Date, public quoteId: string, public feeId: string, public amount: number) {}
}

export class Quote {
  public constructor(
    public id: undefined | string,
    public createdTime: Date,
    public userId: string,
    public sendCurrencyId: string,
    public receiveCurrencyId: string,
    public sendAmount: number,
    public receiveAmount: number,
    public status: QuoteStatus,
    public rate: number,
    public quoteFees?: QuoteFee[],
  ) {}

  public static new(args: Quote): Quote {
    return new Quote(
      args.id,
      args.createdTime ?? new Date(),
      args.userId,
      args.sendCurrencyId,
      args.receiveCurrencyId,
      args.sendAmount,
      args.receiveAmount,
      args.status,
      args.rate,
      args.quoteFees,
    )
  }
}
